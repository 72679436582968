<template>
  <div class="money-record">
    <div class="header-total">
      <div class="bc">
        <div class="bc-l"></div>
        <div class="bc-t">资金流水统计</div>
      </div>
      <div class="total-list">
        <div class="total">
          <div class="total-type">总支出</div>
          <div class="total-value">{{ totalList.totalexpenses }}</div>
        </div>
        <div class="total">
          <div class="total-type">总收入</div>
          <div class="total-value">{{ totalList.totalincome }}</div>
        </div>
        <div class="total">
          <div class="total-type">本月支出</div>
          <div class="total-value">{{ totalList.monthexpenses }}</div>
        </div>
        <div class="total">
          <div class="total-type">本月收入</div>
          <div class="total-value">{{ totalList.monthincome }}</div>
        </div>
        <div class="total">
          <div class="total-type">今日支出</div>
          <div class="total-value">{{ totalList.dayexpenses }}</div>
        </div>
        <div class="total">
          <div class="total-type">今日收入</div>
          <div class="total-value">{{ totalList.dayincome }}</div>
        </div>
      </div>
    </div>
    <el-card>
      <el-form slot="header" class="searchFrom square-input-warp square-btn-warp" size="small"
               :inline="true" lable-width="80px" :model="searchForm">
        <el-form-item label="支付方式:">
          <el-select v-model="searchForm.payType" placeholder="全部">
            <template v-for="payType in payType">
              <el-option :key="payType.value" :label="payType.name"
                         :value="payType.value"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="交易类型:">
          <el-select v-model="searchForm.etype" placeholder="全部">
            <template v-for="eType in eType">
              <el-option :key="eType.value" :label="eType.name" :value="eType.value"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="资金类型:">
          <el-select v-model="searchForm.type" placeholder="全部">
            <template v-for="type in type">
              <el-option :key="type.value" :label="type.name" :value="type.value"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="记录时间:">
          <el-date-picker
            v-model="searchForm.createTimeLimit"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-button size="small" @click="seacher" type="primary">搜索</el-button>
      </el-form>
      <el-table
        :data="recordData"
        v-loading="loading"
        stripe
        style="width: 100%;font-size: 12px;">
        <el-table-column
          prop="address"
          width="50"
          label="类型">
          <template slot-scope="scope">
            <div :style="{color: transactionType(scope.row.type).color}">
              {{ transactionType(scope.row.type).name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          width="100"
          label="支付方式">
          <template slot-scope="scope">
            {{ scope.row.payType | payTypeFilter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          width="100"
          label="支付支金额">
          <template slot-scope="scope">
            {{ scope.row.payMoney ? scope.row.payMoney.toMoney() : 0.00 }}
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          prop="address"
          label="交易类型">
          <template slot-scope="scope">
            {{ scope.row.etype | eTypeFilter }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="流水号">
          <template slot-scope="scope">
            {{ scope.row.orderno }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          label="交易备注">
          <template slot-scope="scope">
            {{ scope.row.remarks }}
          </template>
        </el-table-column>
        <el-table-column
          prop="address"
          width="180"
          label="记录时间">
          <template slot-scope="scope">
            {{ dateToString(scope.row.createtime) }}
          </template>
        </el-table-column>
      </el-table>
      <div class="sctp-pad-tb20 sctp-flex sctp-flex-fe">
        <el-pagination
          :current-page="page"
          :page-size="limit"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 50]"
          layout="prev, pager, next, total, sizes"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
const payTypeOptions = [
  {type: 1, name: '支付宝支付'},
  {type: 2, name: '微信支付'},
  {type: 3, name: '钱包支付'},
  {type: 4, name: '钱包到账'},
  {type: 5, name: '奖励金到账'}
]
const TypeOptions = [
  {type: 1, name: '支出', color: 'red'},
  {type: 2, name: '收入', color: 'blue'}
]
const eTypeOptions = [
  {name: '交易订单', type: 1},
  {name: '商品上架', type: 2},
  {name: '需求上架', type: 3},
  {name: '服务上架', type: 4},
  {name: '文档上架', type: 5},
  {name: '设计上架', type: 6},
  {name: '兼职上架', type: 7},
  {name: '店铺查看联系方式', type: 8},
  {name: '需求查看系方式', type: 9},
  {name: '商品查看联系方式', type: 10},
  {name: '文档查看联系方式', type: 11},
  {name: '设计查看联系方式', type: 12},
  {name: '服务查看联系方式', type: 13},
  {name: '兼职查看联系方式', type: 14},
  {name: '钱包提现', type: 15},
  {name: '交易到账', type: 16},
  {name: '钱包充值', type: 17},
  {name: '缴纳保证金', type: 18},
  {name: '奖励金到账', type: 19},
  {name: '服务订单到账', type: 20},
  {name: '服务托管资金', type: 21},
  {name: '服务订单退款到账', type: 22},
  {name: '商品订单退款到账', type: 23},
]
import {user} from "@/apis/index";

export default {
  name: 'moneyrecord',
  data() {
    return {
      loading: false,
      totalList: {
        totalexpenses: 0,
        totalincome: 0,
        monthexpenses: 0,
        monthincome: 0,
        dayexpenses: 0,
        dayincome: 0,
      },
      searchForm: {
        createTimeLimit: null,
        payType: null,
        etype: null,
        type: null
      },
      recordData: [],
      page: 1,
      limit: 10,
      total: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
    }
  }, filters: {
    payTypeFilter(type) {
      const payObj = payTypeOptions.find(obj => obj.type === type)
      return payObj ? payObj.name : null
    },
    eTypeFilter(type) {
      const payObj = eTypeOptions.find(obj => obj.type === type)
      return payObj ? payObj.name : null
    },
    TypeFilter(type) {
      const payObj = TypeOptions.find(obj => obj.type === type)
      return payObj || {name: '', color: ''}
    },
  },
  methods: {
    getStatistics() {
      user.fundingDetailsStatistics({
        userId: this.user.userId
      }).then(res => {
        let {data} = res;
        this.totalList = data;
      })
    },
    seacher(){
      this.page = 1
      this.getData()
    },
    getData() {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm))
      if (searchForm.createTimeLimit) {
        searchForm.createStart = this.dateToString(new Date(searchForm.createTimeLimit[0]),
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        let dataTime = new Date(searchForm.createTimeLimit[1])
        dataTime = dataTime.setDate(dataTime.getDate()+1)
        searchForm.createEnd = this.dateToString(new Date(dataTime),
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
        delete searchForm.createTimeLimit
      }
      this.loading = true;
      user.fundingDetails({
        ...searchForm,
        userId: this.user.userId,
        page: this.page,
        limit: this.limit
      }).then(res => {
        let {data, count} = res;
        this.recordData = data;
        this.total = count;
      }).finally(() => {
        this.loading = false;
      })
    },
    handleSizeChange(limit) {
      this.limit = limit
      this.getData()
    },
    handleCurrentChange(page) {
      this.page = page
      this.getData()
    }
  },
  computed: {
    transactionType() {
      return function (type) {
        const payObj = TypeOptions.find(obj => obj.type === type)
        return payObj || {name: '', color: ''}
      }
    },
    selectAll() {
      let flag = false
      if (this.recordData && this.recordData.length > 0) {
        flag = this.recordData.every(demand => {
          return demand.selected
        })
      }
      return flag
    },
    indeterminate() {
      if (this.selectAll) {
        return false
      } else {
        let flag = false
        if (this.recordData) {
          flag = this.recordData.some(demand => {
            return demand.selected
          })
        }
        return flag
      }
    },
    payType() {
      return [
        {name: '全部', value: null},
        {name: '支付宝支付', value: 1},
        {name: '微信支付', value: 2},
        {name: '钱包支付', value: 3},
      ]
    },
    eType() {
      return [
        {name: '全部', value: null},
        {name: '交易订单', value: 1},
        {name: '商品上架', value: 2},
        {name: '需求上架', value: 3},
        {name: '服务上架', value: 4},
        {name: '文档上架', value: 5},
        {name: '设计上架', value: 6},
        {name: '兼职上架', value: 7},
        {name: '店铺查看联系方式', value: 8},
        {name: '需求查看系方式', value: 9},
        {name: '商品查看联系方式', value: 10},
        {name: '文档查看联系方式', value: 11},
        {name: '设计查看联系方式', value: 12},
        {name: '服务查看联系方式', value: 13},
        {name: '兼职查看联系方式', value: 14},
        {name: '钱包提现', value: 15},
        {name: '交易到账', value: 16},
        {name: '钱包充值', value: 17},
        {name: '缴纳保证金', value: 18},
        {name: '奖励金到账', value: 19},
        {name: '服务交易到账', value: 20},
      ]
    },
    type() {
      return [
        {name: '全部', value: null},
        {name: '支出', value: 1},
        {name: '收入', value: 2},
      ]
    }
  },
  beforeMount() {
    this.getStatistics();
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.money-record /deep/ {
  .el-input {
    width: 95px !important;
  }

  .el-card__header {
    .el-form-item {
      margin-bottom: 8px;
    }
  }

  .el-card__body {

    .el-table th {
      padding: 8px 0;
    }
  }

  .el-card__header, .el-card__body {
    padding: 10px;
  }
}

.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
  width: 280px;
}

.demand-item {
  border: 1px solid #e5e5e5;
  margin-top: 15px;
  vertical-align: middle;
}

.header-total {
  background-color: white;
  margin-bottom: 7px;
}

.bc {
  display: flex;
  font-size: 15px;
  line-height: 20px;
  padding: 20px 15px;
}

.bc-l {
  width: 3px;
  height: 20px;
  background-color: darkorange;
  margin-right: 10px;
}

.total-list {
  display: flex;
}

.total {
  width: 15%;
  margin: 0px 10px 20px 10px;
  padding: 10px;
  height: 70px;
  text-align: center;
  line-height: 20px;
  background-color: rgb(250, 242, 230);
}

.total-type {
  font-size: 14px;
  margin-bottom: 7px;
}

.total-value {
  font-size: 18px;
  color: #F60;
}
</style>
